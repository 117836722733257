<template>

    <section>


        <div class="container">

           

            <div class="row">

                  <div class="col-md-4"></div>

                <div class="col-md-4" style="padding-bottom:10px;">

                    <div style="padding-top: 5px; text-align: center;">
                       
                        <h3 style="color:grey;"> Escape Lounge</h3>
                       
                        <p style="color:grey;">Book a table</p>
                    </div>

                    <form style="text-align:center;">
                        <label style="color:grey;">Pick a date</label>
                        <input type="date" class="form-control" @change="check_table()" v-model="date">
                    </form>                  

                </div>

                <div class="col-md-4"></div>

                  <div class="col-md-1"></div>

                <div class="col-md-9">
                    
                    <div class="row shadow-lg rounded" style="background-color:#000;" v-if="date">

            <div class="col-md-12" style="padding-bottom: 10px; padding-top: 10px; text-align: center;">
                <p style="color:grey;">Pick a booth</p>
            </div>
                       
                        <div class="col-md-4 col-4" v-for="table in tables" style="padding: 10px; text-align:center;">

                            
                            
                            <div v-if="table=='1001'">
                                <button :disabled="picked_slots.includes(table)" class="btn seat-btn" :class="{ 'disable-table' : picked_slots.includes(table) }" @click="pick_table(table)"> <img src="assets/images/2-removebg-preview.png" style="height: 70px;"> <span class="badge bg-danger"> ${{ table }} </span> </button>
                                <span class="badge bg-dark" v-if="!picked_slots.includes(table)"> <i class="bi bi-check-circle"></i> Available</span>
                                <span class="badge bg-danger" v-if="picked_slots.includes(table)"><i class="bi bi-x-circle"></i>Not available</span>
                            </div>

                             <div v-if="table=='1005'">
                                <button :disabled="picked_slots.includes(table)" class="btn seat-btn" :class="{ 'disable-table' : picked_slots.includes(table) }" @click="pick_table(table)"> <img src="assets/images/1-removebg-preview.png" style="height: 70px;"> <span class="badge bg-danger"> ${{ table }} </span> </button>
                                <span class="badge bg-dark" v-if="!picked_slots.includes(table)"> <i class="bi bi-check-circle"></i> Available</span>
                                <span class="badge bg-danger" v-if="picked_slots.includes(table)"><i class="bi bi-x-circle"></i> Not available</span>
                            </div>

                             
                            <div v-if="table=='1002'">
                                <button :disabled="picked_slots.includes(table)" class="btn seat-btn" :class="{ 'disable-table' : picked_slots.includes(table) }" @click="pick_table(table)"> <img src="assets/images/6-removebg-preview.png" style="height: 70px;"> <span class="badge bg-danger">${{ table }} </span> </button>
                                <span class="badge bg-dark" v-if="!picked_slots.includes(table)"> <i class="bi bi-check-circle"></i> Available</span>
                                <span class="badge bg-danger" v-if="picked_slots.includes(table)"> <i class="bi bi-x-circle"></i> Not available</span>
                            </div>

                             
                            <div v-if="table=='1003'">
                                <button :disabled="picked_slots.includes(table)" class="btn seat-btn" :class="{ 'disable-table' : picked_slots.includes(table) }" @click="pick_table(table)"> <img src="assets/images/2-removebg-preview.png" style="height: 70px;"> <span class="badge bg-danger">${{ table }} </span> </button>
                                <span class="badge bg-dark" v-if="!picked_slots.includes(table)"> <i class="bi bi-check-circle"></i> Available</span>
                                <span class="badge bg-danger" v-if="picked_slots.includes(table)"> <i class="bi bi-x-circle"></i> Not available</span>
                            </div>

                             
                            <div v-if="table=='1004'">
                                <button :disabled="picked_slots.includes(table)" class="btn seat-btn" :class="{ 'disable-table' : picked_slots.includes(table) }" @click="pick_table(table)"> <img src="assets/images/6-removebg-preview.png" style="height: 70px;"> <span class="badge bg-danger">${{ table }} </span> </button>
                                <span class="badge bg-dark" v-if="!picked_slots.includes(table)"> <i class="bi bi-check-circle"></i> Available</span>
                                <span class="badge bg-danger" v-if="picked_slots.includes(table)"> <i class="bi bi-x-circle"></i> Not available</span>
                            </div>

                            <div v-if="table=='501'">
                                <button :disabled="picked_slots.includes(table)" class="btn seat-btn" :class="{ 'disable-table' : picked_slots.includes(table) }" @click="pick_table(table)"> <img src="assets/images/4-removebg-preview.png" style="height: 70px;"> <span class="badge bg-danger">${{ table }} </span> </button>
                                <span class="badge bg-dark" v-if="!picked_slots.includes(table)"> <i class="bi bi-check-circle"></i> Available</span>
                                <span class="badge bg-danger" v-if="picked_slots.includes(table)"> <i class="bi bi-x-circle"></i> Not available</span>
                            </div>

                            <div v-if="table=='1007'">
                                <button :disabled="picked_slots.includes(table)" class="btn seat-btn" :class="{ 'disable-table' : picked_slots.includes(table) }" @click="pick_table(table)"> <img src="assets/images/4-removebg-preview.png" style="height: 70px;"> <span class="badge bg-danger">${{ table }} </span> </button>
                                <span class="badge bg-dark" v-if="!picked_slots.includes(table)"> <i class="bi bi-check-circle"></i> Available</span>
                                <span class="badge bg-danger" v-if="picked_slots.includes(table)"> <i class="bi bi-x-circle"></i> Not available</span>
                            </div>

                            <div v-if="table=='502'">
                                <button :disabled="picked_slots.includes(table)" class="btn seat-btn" :class="{ 'disable-table' : picked_slots.includes(table) }" @click="pick_table(table)"> <img src="assets/images/8-removebg-preview.png" style="height: 70px;"> <span class="badge bg-danger">${{ table }} </span> </button>
                                <span class="badge bg-dark" v-if="!picked_slots.includes(table)"> <i class="bi bi-check-circle"></i> Available</span>
                                <span class="badge bg-danger" v-if="picked_slots.includes(table)"> <i class="bi bi-x-circle"></i> Not available</span>
                            </div>

                            <div v-if="table=='vip'">
                                <button :disabled="picked_slots.includes(table)" class="btn seat-btn" :class="{ 'disable-table' : picked_slots.includes(table) }" @click="pick_table(table)"> <img src="assets/images/3-removebg-preview.png" style="height: 70px;"> <span class="badge bg-danger">${{ table }} </span> </button>
                                <span class="badge bg-dark" v-if="!picked_slots.includes(table)"> <i class="bi bi-check-circle"></i> Available</span>
                                <span class="badge bg-danger" v-if="picked_slots.includes(table)"> <i class="bi bi-x-circle"></i> Not available</span>
                            </div>

                              <div v-if="table=='1006'">
                                <button :disabled="picked_slots.includes(table)" class="btn seat-btn" :class="{ 'disable-table' : picked_slots.includes(table) }" @click="pick_table(table)"> <img src="assets/images/1-removebg-preview copy.png" style="height: 70px;"> <span class="badge bg-danger">${{ table }} </span> </button>
                                <span class="badge bg-dark" v-if="!picked_slots.includes(table)"> <i class="bi bi-check-circle"></i> Available</span>
                                <span class="badge bg-danger" v-if="picked_slots.includes(table)"> <i class="bi bi-x-circle"></i> Not available</span>
                            </div>

                            <div v-if="table==''">
                               
                            </div>

                          

                        </div>

                    </div>

                 

                </div>

                  <div class="col-md-1"></div>

                <div class="col-md-6 col-6" style="padding-top: 20px;">

                    <div style="text-align:left;">
                        <h6 style="color:grey;"> <i class="bi bi-phone text-danger"></i> 1234567890</h6>
                    </div>
                </div>

                 <div class="col-md-6 col-6" style="padding-top: 20px;">

                    <div style="text-align:right;">
                        <h6 style="color:grey;"> <i class="bi bi-envelope text-danger"></i> test@gmail.com </h6>

                    </div>
                </div>



            </div>

        </div>


        <div class="form shadow-lg  p-3" v-if="show_form">

            <div>
                <h4 style="color:grey;">Contact Information</h4>
            </div>

            <p style="color:grey;"> Date : {{ date }},Booth : {{ picked_table }} </p>

            <form @submit.prevent="make_order()">

                <div class="row">

                    <div class="col-md-6 col-6" style="padding-top: 20px;">
                        <input type="text" class="form-control input-design" placeholder="First name" v-model="first_name">
                    </div>

                    <div class="col-md-6 col-6" style="padding-top: 20px;">
                        <input type="text" class="form-control input-design" placeholder="Last name" v-model="last_name">
                    </div>

                    <div class="col-md-6 col-6" style="padding-top: 20px;">
                        <input type="text" class="form-control input-design" placeholder="Email" v-model="email">
                    </div>

                    <div class="col-md-6 col-6" style="padding-top: 20px;">
                        <input type="text" class="form-control input-design" placeholder="Phone" v-model="phone">
                    </div>

                    <div class="col-md-6" style="padding-top: 20px;">
                        <button class="btn theme-btn" type="button" style="margin-right: 10px;" @click="show_form=false">Cancel</button>
                    <button :disabled="disabled" class="btn theme-btn" type="submit">{{ text }}</button>
                    </div>

                </div>

            </form>

        </div>


    </section>

</template>

<script>
import axios from 'axios'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
    name : 'home',
    data () {
        return {
            tables : ['1001','1005','1007','1002','1006','501','1003','','502','','','','','',''],
            date : '',
            picked_slots : [],
            show_form : false,
            picked_date : '',
            first_name : '',
            last_name : '',
            email : '',
            phone : '',
            msg : false,
            disabled : false,
            text : 'Make Order'
        }
    },
    methods : {
        async check_table(){
            const res = await axios.post(this.$store.state.api_url+'api/check-table',{
                date : this.date
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            this.picked_slots = res
            this.text = 'Make Order'
            this.disabled = false
        },

        pick_table(table){
            if(this.date==''){
                alert('You must pick a date first')
            }else{
            this.show_form = true
            this.picked_table = table
            this.msg = false
            }
          
        },

        async make_order(){

            if(!this.first_name){
                toast.error("Please enter first name", {
        autoClose: 3000,
        theme : 'dark',
        position:'top-center'
      }); // ToastOptions
                return
            }

            if(!this.last_name){
                toast.error("Please enter last name", {
        autoClose: 3000,
        theme : 'dark',
        position:'top-center'
      }); // ToastOptions
                return
            }

            if(!this.email){
                toast.error("Please enter email", {
        autoClose: 3000,
        theme : 'dark',
        position:'bottom-right'
      }); // ToastOptions
                return
            }

            if(!this.phone){
                toast.error("Please enter phone", {
        autoClose: 3000,
        theme : 'dark',
        position:'top-center'
      }); // ToastOptions
                return
            }
            this.disabled = true
            this.text = 'Please Wait...'

            const res = await axios.post(this.$store.state.api_url+'api/make-order',{
                first_name : this.first_name,
                last_name : this.last_name,
                email : this.email,
                phone : this.phone,
                date : this.date,
                picked_table : this.picked_table
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.msg = true
            this.show_form = false
            //this.date = ''
            this.picked_table = ''
            this.first_name = ''
            this.last_name = ''
            this.email = ''
            this.phone = ''
            this.check_table()
            toast.success("Order submitted successfully", {
        autoClose: 3000,
        theme : 'dark',
        position:'top-center'
      }); // ToastOptions
            window.scrollTo(0,0)
        }
    },

    created (){
        window.scrollTo(0,0)
        toast.success("Welcome Escape Lounge", {
        autoClose: 3000,
        theme : 'dark',
        position:'bottom-right'
      }); // ToastOptions
    }
}

</script>

<style>

.disable-table{
    
}

</style>